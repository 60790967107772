import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { HMSRoomProvider } from "@100mslive/react-sdk";

import App from "./App";
import KineProvider from "./context/KineProvider";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <StrictMode>
    <KineProvider>
      <HMSRoomProvider>
        <App />
      </HMSRoomProvider>
    </KineProvider>
  </StrictMode>,
  rootElement
);
