import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CreateSession from "./CreateSession";
import Conference from "./Conference";
import "./styles.css";
import "./globalStyles/index.scss";
import JoinForm from "./JoinForm";
import Home from "./pages/home";
import {
  HMSRoomState,
  selectIsConnectedToRoom,
  selectRoomState,
  useHMSActions,
  useHMSStore
} from "@100mslive/react-sdk";
import Footer from "./Footer";
import { Loader } from "./Loader";
import Header from "./components/Header";
import Meet from "./pages/meet/index";
import Create from "./pages/create";
import Stream from "./pages/stream";
import Modal from "./components/Modal";
import Input from "./components/Input";
import Dropdown from "./components/Dropdown";
import { web3Accounts, web3Enable } from "@polkadot/extension-dapp";
import { Loading } from "./components/Loading";
import { login } from "./service/login";
import { KineProvider } from "./context/KineProvider";
import Login from "./pages/login";

function App() {
  const loadingStates = [HMSRoomState.Connecting, HMSRoomState.Disconnecting];

  const roomState = useHMSStore(selectRoomState);

  const [showModal, setShowModal] = useState(false);
  const [isLoginFetch, setIsLoginFetch] = useState(false);
  const openModal = () => {
    setShowModal(true);
  };

  if (loadingStates.includes(roomState) || !roomState) {
    return <Loader />;
  }

  return (
    <div>
      {showModal && (
        <Login
          setShowModal={setShowModal}
          showModal={showModal}
          isLoginFetch={isLoginFetch}
          setIsLoginFetch={setIsLoginFetch}
        />
      )}
      <Router>
        <Header showModal={openModal} account={isLoginFetch} />
        <div className="page-body">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/create" element={<Create />} />
            <Route path="/meet/:id" element={<Meet />} />
            <Route path="/stream/:roomCode" element={<Stream />} />
          </Routes>
        </div>
      </Router>
    </div>
  );
}

export default App;
