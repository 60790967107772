import React, { useState } from "react";
import KineContext from "./KineContext";
import Cookies from "js-cookie";

const KineProvider = ({ children }) => {
  const [loggeduser, setLoggedUser] = useState([]);
  const [user, setUser] = useState([]);
  let userData = Cookies.get("kinera-cookie");
  const updateUserLogin = (user) => {
    setLoggedUser(user);
  };

  const getRecoveryUser = () => {
    if (userData) {
      
      setLoggedUser(JSON.parse(userData))
    } else {
      setUser(null);
    }
  };

  React.useEffect(() => {
    getRecoveryUser();
  }, []);

  return (
    <KineContext.Provider
      value={{
        loggeduser,
        updateUserLogin
      }}
    >
      {children}
    </KineContext.Provider>
  );
};

export default KineProvider;
