import API from "./api";
import Cookies from "js-cookie"; // Adicione essa dependência

const login = async (user, password) => {
  const headers = {
    "x-cors-api-key": process.env.REACT_APP_KEY_PROXY
  };

  try {
    let payload = {
      user: user,
      password: password
    };
    let req = await API.post(`/api/login`, payload, {
      headers: headers
    });

    if (req && req.data) {
      // Armazena os dados do usuário no cookie kinera-cookie
      Cookies.set("kinera-cookie", JSON.stringify(req.data), {
        sameSite: "None",
        secure: true
      });
    }

    return req;
  } catch (err) {
    if (err && err.response && err.response.data) {
      return err.response.data;
    } else {
      return err;
    }
  }
};

const logout = async () => {
  const headers = {
    "x-cors-api-key": process.env.REACT_APP_KEY_PROXY
  };

  try {
    let req = await API.post(
      `/api/logout`,
      {},
      {
        headers: headers
      }
    );

    // Remove o cookie kinera-cookie
    Cookies.remove("kinera-cookie");

    return req.data;
  } catch (err) {
    if (err && err.response && err.response.data) {
      return err.response.data;
    } else {
      return err;
    }
  }
};

export { login, logout };
