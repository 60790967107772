import {
  selectIsPeerAudioEnabled,
  selectIsPeerVideoEnabled,
  useHMSStore,
  useVideo
} from "@100mslive/react-sdk";
import Controls from "./PeersControl";
import { MicOffIcon, PersonIcon } from "@100mslive/react-icons";
import { useContext } from "react";
import KineContext from "../../../context/KineContext";

const Peers = ({ peer }) => {
  const { loggeduser } = useContext(KineContext);
  const { videoRef, audioRef } = useVideo({
    trackId: peer.videoTrack
  });
  const isPeerAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(peer.id));
  const isPeerVideoEnabled = useHMSStore(selectIsPeerVideoEnabled(peer.id));
  return (
    <div className="peer-container relative">
      {!isPeerAudioEnabled && (
        <div
          style={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
            zIndex: "100",
            backgroundColor: "#fff",
            padding: "0.5rem",
            borderRadius: "0.75rem",
            height: "2rem",
            width: "2rem"
          }}
        >
          <MicOffIcon height={16} width={16} />
        </div>
      )}
      <video
        ref={videoRef}
        className={`peer-video ${peer.isLocal ? "local" : ""}`}
        autoPlay
        playsInline
      />

      <audio ref={audioRef} autoPlay controls={false} />
      {loggeduser && loggeduser.name && (
        <div className="peer-name p-4">
          {loggeduser.name} {peer.isLocal ? "(You)" : ""}
        </div>
      )}

      {!isPeerVideoEnabled ? (
        <div className="peer-video video-cover">
          <PersonIcon height={48} width={48} />
        </div>
      ) : null}
    </div>
  );
};

export default Peers;
