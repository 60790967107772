import React, { useEffect } from "react";

const Input = ({ value, type, placeholder, onChange, icon, onClick }) => {
  useEffect(() => {
    if (onChange) {
      onChange("");
    }
  }, []);
  return (
    <div className="relative">
      <input
        type={type}
        className="input text-white"
        onChange={(e) => {
          onChange(e.target.value);
        }}
        placeholder={placeholder}
        value={value}
      ></input>
      {icon && (
        <span
          className="material-symbols-outlined absolute top-1 right-2 text-white hover:opacity-70 cursor-pointer"
          onClick={onClick}
        >
          {icon}
        </span>
      )}
    </div>
  );
};

export default Input;
