import React, { useState, useRef } from "react";
import {
  MicOffIcon,
  MicOnIcon,
  VideoOffIcon,
  VideoOnIcon,
  RecordIcon, // Ícone para gravação
  StopIcon // Ícone para parar a gravação
} from "@100mslive/react-icons";
import { useAVToggle } from "@100mslive/react-sdk";

function Controls() {
  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle();

  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);
  const displayStreamRef = useRef(null); // Referência para o display stream

  const startRecording = async () => {
    try {
      // Captura o vídeo e o áudio da tela (incluindo áudio de fundo de outros aplicativos/abas)
      const displayStream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
        audio: true // Captura o áudio do sistema, se permitido
      });

      // Armazena o displayStream na referência
      displayStreamRef.current = displayStream;

      // Captura o áudio do microfone local também (opcional)
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true
      });

      // Combina os streams de vídeo e áudio em um único stream
      const combinedStream = new MediaStream([
        ...displayStream.getTracks(), // Adiciona vídeo e áudio capturados
        ...audioStream.getAudioTracks() // Adiciona áudio do microfone
      ]);

      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: "video/webm; codecs=vp8,opus" // Define o codec de gravação
      });

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          recordedChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const blob = new Blob(recordedChunksRef.current, {
          type: "video/webm"
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "meet_recording.webm";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        recordedChunksRef.current = []; // Limpa os chunks gravados

        // Para o compartilhamento de tela
        displayStream.getTracks().forEach((track) => track.stop());
      };

      mediaRecorder.start();
      mediaRecorderRef.current = mediaRecorder;
      setIsRecording(true);
    } catch (error) {
      console.error("Falha ao iniciar a gravação:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return (
    <div>
      <div className="control-bar-meet container w-fit mx-auto">
        <button
          className={`btn-control flex items-center justify-center ${
            isLocalAudioEnabled ? "" : "highlight"
          }`}
          onClick={toggleAudio}
        >
          {isLocalAudioEnabled ? <MicOnIcon /> : <MicOffIcon />}
        </button>
        <button
          className={`btn-control flex items-center justify-center ${
            isLocalVideoEnabled ? "" : "highlight"
          }`}
          onClick={toggleVideo}
        >
          {isLocalVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
        </button>

        <button
          className={
            isRecording
              ? `btn-control flex items-center justify-center`
              : "btn-control flex items-center justify-center bg-[#2E3038]"
          }
          onClick={isRecording ? stopRecording : startRecording}
        >
          {isRecording ? (
            <span class="material-symbols-outlined">cancel</span>
          ) : (
            <span class="material-symbols-outlined">radio_button_checked</span>
          )}
        </button>
      </div>
      <div className="flex items-center justify-center m-4">
        {isRecording ? <p>Meet Recording on</p> : ""}
      </div>
    </div>
  );
}

export default Controls;
