import API from "./api";

const getUserData = async (user) => {
  try {
    let req = await API.get(`/api/users/ipfs/` + user);
    return req.data;
  } catch (err) {
    console.log(err);
  }
};

export { getUserData };
